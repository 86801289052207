<template>
  <flipCards :cards_="cards"/>
</template>

<script>
import { defineComponent } from 'vue'
import flipCards from '@/pages/docs/globalComponents/flipCards'

const cards = [
    {
      front: 'Priverstinio transporto priemonių nuvežimo ir saugojimo paslaugos',
      back: '✅ Taip',
      flipped: false,
    },
    {
      front: 'Automobilio lizingo sutartis',
      back: '✅ Taip',
      flipped: false,
    },
    {
      front: 'Bilietų platinimo paslaugos',
      back: '✅ Taip',
      flipped: false,
    },
    {
      front: 'Banko paskola ',
      back: '❌ Ne',
      flipped: false,
    },
    {
      front: 'Advokato paslaugos atstovavimui teisme',
      back: '❌ Ne',
      flipped: false,
    },
    {
      front: 'Automobilių privaloma techninė apžiūra',
      back: '✅ Taip',
      flipped: false,
    },
    {
      front: 'Pavėžėjimo (taksi) paslaugos ',
      back: '✅ Taip',
      flipped: false,
    },
    {
      front: 'Komandiruotės išlaidos (viešbučiai, kuras ir pan.) ',
      back: '❔ Galbūt',
      flipped: false,
    },
];

export default defineComponent({
  components: {
    flipCards,
  },
  setup() {
    return {
      cards
    }
  },

  methods: {
  }
});
</script>

<style scoped>
</style>
